export const emailRules = [
  v => !!v || 'E-mail is required',
  v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
];

export const createFormData = (data) => {
  let formData = new FormData();
  for (let prop in data)
    formData.append(prop, data[prop]);
  return formData;
};