<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <div class="font-weight-medium">
                Contact
            </div>
        </div>

        <v-card class="mx-auto my-4">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>N</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Message</th>
                            <th class="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(contact, index) in getContacts">
                        <td>{{ index + 1 }}</td>
                        <td>{{ contact.name }}</td>
                        <td>{{ contact.data }}</td>
                        <td class="contact__message">{{ contact.message }}</td>
                        <td class="text-right">
                            <template>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openForm(contact, createContentObject)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="info">
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="deleteItem(contact)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="red">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>

        <Modal v-model="modal"
               width="1140px"
               title="Contact form">
            <Form v-if="modal"
                  :data-object="dataObject"
                  @close-modal="modal = false"
                  @send-contact="sendContact"
            />
        </Modal>
    </div>
</template>

<script>
    import Form from "./Form";
    import formModal from "@/mixins/formModal";
    import deleteModal from "@/mixins/deleteModal";
    import { baseURL } from "@/services/api";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "List",
        data: () => ({
            baseURL,
            lang: 'uz',
            languages: ['uz', 'ru', 'en'],
        }),
        components: { Form },
        computed: {
            ...mapGetters({
                getContacts: 'getContacts',
            })
        },
        mixins: [formModal, deleteModal],
        methods: {
            ...mapActions({
                fetchContacts: 'fetchContacts',
                updateContact: 'updateContact',
                deleteContact: 'deleteContact',
            }),

            createContentObject (content) {
                const object = {
                    name: content.name,
                    data: content.data,
                    message: content.message,
                    id: content.id
                };

                return object;
            },

            async sendContact(data) {
                await this.updateContact(data);
                await this.fetchContacts();
                this.modal = false;
            },

            async deleteItem(content) {
                await this.deleteContact(content.id);
                await this.fetchContacts();
            }
        },
        async mounted() {
            await this.fetchContacts();
        }
    }
</script>

<style scoped lang="scss">
    .contact {
       &__message {
           max-width: 250px;
           overflow-x: auto;
       }
    }
</style>
