<template>
    <div>
        <v-form @submit.prevent="submit">
            <v-text-field v-model="dataObject.name"
                          label="Name"
                          clearable/>

            <v-text-field v-model="dataObject.data"
                          label="Phone"
                          clearable/>

            <v-text-field v-model="dataObject.message"
                          label="Message"
                          clearable/>
            <v-row no-gutters>
                <v-spacer/>
                <v-btn @click="closeModal" color="primary" text class="mr-3">Cancel</v-btn>
                <v-btn type="submit" color="primary" depressed>Save</v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import {createFormData} from "@/services/validationRules";

    export default {
        props: {
            dataObject: Object
        },
        name: "Form",
        data: () => ({

        }),
        methods: {
            submit() {
                this.$emit('send-contact', this.dataObject)
            },
            closeModal() {
                this.$emit('close-modal');
            }
        },
    }
</script>

<style scoped>

</style>
